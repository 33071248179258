import { PropsWithChildren } from 'react';
import * as React from 'react';
import {
	ExclamationIcon,
	XCircleIcon,
	CheckCircleIcon,
	InformationCircleIcon,
} from '@heroicons/react/solid';
import cc from 'classcat';

export enum AlertType {
	error = 'error',
	warning = 'warning',
	success = 'success',
	info = 'info',
}

interface AlertProps extends PropsWithChildren {
	type: AlertType;
	className?: string;
}

export const Alert: React.FC<AlertProps> = (props) => {
	const { children, type, className } = props;

	const classNames = cc([
		'h-5 w-5',
		{
			'text-skin-alert-icon-warning': type === AlertType.warning,
			'text-skin-alert-icon-error': type === AlertType.error,
			'text-skin-alert-icon-success': type === AlertType.success,
			'text-skin-alert-icon-info': type === AlertType.info,
		},
	]);

	return (
		<div
			className={cc([
				'alert mb-4 w-full rounded-skin-alert border-skin-alert p-skin-alert',
				className,
				{
					'border-skin-alert-warning bg-skin-alert-warning':
						type === AlertType.warning,
					'border-skin-alert-error bg-skin-alert-error':
						type === AlertType.error,
					'border-skin-alert-success bg-skin-alert-success':
						type === AlertType.success,
					'border-skin-alert-info bg-skin-alert-info': type === AlertType.info,
				},
			])}
		>
			<div className="flex">
				<div className="shrink-0">
					{type === AlertType.warning && (
						<ExclamationIcon className={classNames} />
					)}
					{type === AlertType.error && <XCircleIcon className={classNames} />}
					{type === AlertType.success && (
						<CheckCircleIcon className={classNames} />
					)}
					{type === AlertType.info && (
						<InformationCircleIcon className={classNames} />
					)}
				</div>
				<div className="ml-3">
					<h3
						className={cc([
							'font-headline text-base',
							{
								'text-skin-alert-message-warning': type === AlertType.warning,
								'text-skin-alert-message-error': type === AlertType.error,
								'text-skin-alert-message-success': type === AlertType.success,
								'text-skin-alert-message-info': type === AlertType.info,
							},
						])}
					>
						{children}
					</h3>
				</div>
			</div>
		</div>
	);
};
