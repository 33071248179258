import { Alert, AlertType } from '@vintro/components/vintro/alert';
import cc from 'classcat';
import { useRouter } from 'next/router';
import * as React from 'react';

export const PostAccountRemoveConfirmAlert = () => {
	const router = useRouter();
	const [alertMessage, setAlertMessage] = React.useState(null);
	const [messageSet, setMessageSet] = React.useState(false);

	React.useEffect(() => {
		if (router.isReady && !messageSet) {
			router.query['remove-company'] === 'true'
				? setAlertMessage(`The company has been successfully removed`)
				: setAlertMessage(`Your account has been successfully removed`);

			setMessageSet(true);
		}
	}, [router, messageSet]);

	return (
		<Alert
			className={cc({
				'login-success-remove-company': router.pathname === '/login',
				'workspace-success-remove-company':
					router.pathname === '/login/workspace',
			})}
			type={AlertType.success}
		>
			{alertMessage}
		</Alert>
	);
};
