import * as React from 'react';
import { InputGroup } from '@vintro/components/vintro/inputs/group/group';
import Button from '@vintro/components/vintro/button';
import { TextInput } from '@vintro/components/vintro/input';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

export interface FormLoginSubmitData {
	username: string;
	password: string;
}

export function useFormValidation() {
	const schema = yup.object().shape({
		username: yup.string().required('Email is required'),
		password: yup.string().required('Password is required'),
	});

	return schema;
}

export function FormLogin(props) {
	const schema = useFormValidation();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(schema),
	});

	return (
		<form
			action={props.action}
			method={props.method}
			onSubmit={handleSubmit(props.onSubmit)}
			className="login-form"
		>
			<input
				type="hidden"
				defaultValue={props.csrfToken}
				{...register('csrfToken')}
			/>
			<InputGroup>
				<TextInput
					label={'Email'}
					errorMessage={errors?.username?.message}
					inputProps={{
						required: true,
						type: 'email',
						className: 'w-full',
						placeholder: 'Enter your email',
						...register('username'),
					}}
				/>
			</InputGroup>
			<InputGroup>
				<TextInput
					label={'Password'}
					errorMessage={errors?.password?.message}
					inputProps={{
						required: true,
						className: 'w-full',
						type: 'password',
						placeholder: 'Enter your password',
						...register('password'),
					}}
				/>
			</InputGroup>
			<Button
				buttonProps={{ type: 'submit', className: 'w-full justify-center' }}
			>
				<span>Sign In</span>
			</Button>
		</form>
	);
}
