import { PropsWithChildren } from 'react';
import * as React from 'react';
import cc from 'classcat';

interface InputGroupProps extends PropsWithChildren {
	className?: string;
}

export const InputGroup: React.FC<InputGroupProps> = (props) => {
	const { children, className } = props;

	return (
		<div className={cc([className, { 'mb-2 sm:mb-4': !className }])}>
			{children}
		</div>
	);
};
