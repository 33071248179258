import { AppSiteAwareText } from '@/components/app-site-aware-text';
import { AppSiteAwareTextKeys } from '@/components/app-site-aware-text/definitions/definition-keys';
import { PostAccountRemoveConfirmAlert } from '@/components/alert/post-account-remove-confirm-alert';
import { FormLogin, FormLoginSubmitData } from '@/components/forms/login/login';
import {
	defaultTrackingMetadata,
	TrackEvent,
} from '@theme/analytics/analytics';
import cc from 'classcat';
import { getCsrfToken, getSession, signIn } from 'next-auth/react';
import Link from 'next/link';
import {
	HeadingSize,
	TextHeading,
	TextParagraph,
	TextParagraphSize,
} from '@vintro/components/vintro/typography/typography';
import Button, { ButtonType } from '@vintro/components/vintro/button';
import { Divider } from '@vintro/components/vintro/divider';
import * as React from 'react';
import { Alert, AlertType } from '@vintro/components/vintro/alert';
import { LoadingSpinner } from '@vintro/components/vintro/loading-spinner/loading-spinner';
import {
	BackgroundColor,
	TextColor,
} from '@vintro/components/vintro/global/enum';
import { Session } from 'next-auth';
import { useAnalytics } from 'use-analytics';
import { useRouter } from 'next/router';

const ERROR_MESSAGE_DEFAULT_STATE = null;

export const PageLogin = (props) => {
	const { identify, track } = useAnalytics();
	const [errorMessage, setErrorMessage] = React.useState(
		ERROR_MESSAGE_DEFAULT_STATE,
	);
	const [loading, setLoading] = React.useState(false);
	const router = useRouter();
	const [removeCompanyMessage, setRemoveCompanyMessage] = React.useState(false);

	React.useEffect(() => {
		if (router?.isReady && !removeCompanyMessage) {
			const removeCompany = Boolean(router?.query?.['remove-company']);
			setRemoveCompanyMessage(removeCompany);

			if (removeCompany) {
				setTimeout(
					() => router.replace('/login', undefined, { shallow: true }),
					500,
				);
			}
		}
	}, [router, removeCompanyMessage]);

	async function onSubmit(data: FormLoginSubmitData) {
		setErrorMessage(ERROR_MESSAGE_DEFAULT_STATE);
		setLoading(true);

		track(TrackEvent.AuthLoginViewSignInClicked, defaultTrackingMetadata);

		try {
			const response = await signIn('credentials', {
				redirect: false,
				username: data.username,
				password: data.password,
			});

			if (!response.ok) {
				switch (response.error) {
					case 'CredentialsSignin':
						setErrorMessage(
							'The username or password you entered is incorrect',
						);
						break;
					default:
						setErrorMessage(response);
						break;
				}
				throw new Error('');
			}

			const session: Session = await getSession();

			if (session?.company?.id) {
				// Do a hard refresh to avoid issues with uploads and chat not loading
				location.href = '/dashboard';
			}

			if (session?.user?.id) {
				identify(session.user.id);
			}

			// Do a hard refresh to avoid issues with uploads and chat not loading
			location.href = '/login/workspace';
		} catch (e) {
			//
		}

		setLoading(false);
	}

	return (
		<div className={cc(['space-y-6 divide-y divide-neutral-300'])}>
			<div>
				<TextHeading
					className={cc(['login-panel-heading mb-6'])}
					headingSize={HeadingSize.h4}
				>
					<AppSiteAwareText
						textKey={AppSiteAwareTextKeys['signin.form.heading']}
					/>
				</TextHeading>

				{errorMessage && (
					<Alert className={'login-error-alert'} type={AlertType.error}>
						{errorMessage}
					</Alert>
				)}

				{removeCompanyMessage && <PostAccountRemoveConfirmAlert />}

				<FormLogin
					csrfToken={props.csrfToken}
					method="POST"
					action="/api/auth/credentials"
					onSubmit={onSubmit}
				/>

				{loading && <LoadingSpinner />}

				<div className={cc(['my-4 text-center'])}>
					<Link
						href="/auth/reset"
						className={cc([
							'login-forgot-password-link text-skin-link-base underline',
						])}
						onClick={() =>
							track(
								TrackEvent.AuthLoginViewForgotPasswordClicked,
								defaultTrackingMetadata,
							)
						}
					>
						Forgot password?
					</Link>
				</div>

				{process.env.NEXT_PUBLIC_OAUTH_LOGIN_ENABLED === 'true' && (
					<OAuthLoginActions
						dividerText="or Login with Linkedin"
						buttonText="Login with Linkedin"
					/>
				)}
			</div>
			<div>
				<div className="login-panel-help-text">
					<TextHeading
						className={cc(['login-panel-help-text-heading', 'my-3'])}
						headingSize={HeadingSize.h7}
					>
						{"Don't have an account?"}
					</TextHeading>
					<TextParagraph
						className={cc(['login-panel-help-text-instructions', 'mb-4'])}
						textParagraphSize={TextParagraphSize.p6}
					>
						Join to discover new business opportunities from enterprise clients.
					</TextParagraph>
				</div>
				<div className={cc(['flex flex-col gap-4 sm:flex-row'])}>
					<Link
						href="/signup"
						passHref={true}
						className={cc(['w-full'])}
						onClick={() =>
							track(
								TrackEvent.AuthLoginViewJoinNowClicked,
								defaultTrackingMetadata,
							)
						}
					>
						<Button
							type={ButtonType.secondary}
							buttonProps={{
								className: 'login-panel-signup-button w-full justify-center',
							}}
						>
							Join Now
						</Button>
					</Link>
				</div>
			</div>
		</div>
	);
};

interface OAuthLoginActionsProps {
	dividerText: string;
	buttonText: string;
}

export const OAuthLoginActions: React.FC<OAuthLoginActionsProps> = (props) => {
	const { dividerText, buttonText } = props;

	return (
		<>
			<Divider
				title={dividerText}
				className="mt-4 mb-2"
				textColor={TextColor.black}
			/>

			<Link
				href="http://www.linkedin.com"
				passHref={true}
				className={cc(['w-full'])}
			>
				<Button
					buttonProps={{
						className: 'w-full justify-center',
					}}
					type={ButtonType.custom}
					backgroundColor={BackgroundColor.blue}
					textColor={TextColor.white}
				>
					<svg id="linkedin" viewBox="0 0 24 24" className="mr-4 h-6 w-6">
						<g clipPath="url(#clip0)">
							<path
								d="M20.9507 20.4511H17.3941V14.8814C17.3941 13.5535 17.3704 11.8431 15.5443 11.8431C13.6926 11.8431 13.4086 13.2903 13.4086 14.7855V20.4511H9.85131V8.9974H13.2653V10.5626H13.3133C14.0094 9.3725 15.3038 8.66197 16.6815 8.71372C20.2862 8.71372 20.9507 11.0864 20.9507 14.1689V20.4511ZM5.83991 7.4322C4.70013 7.43256 3.77587 6.50885 3.77552 5.36905C3.77516 4.22926 4.69885 3.30498 5.83863 3.30463C6.9784 3.30427 7.90266 4.22798 7.90302 5.36777C7.90319 5.91513 7.68592 6.44013 7.29901 6.82728C6.9121 7.21444 6.38725 7.43203 5.83991 7.4322ZM7.6187 20.4511H4.05791V8.9974H7.61806L7.6187 20.4511ZM22.7241 0.000128875H2.27144C1.30477 -0.0107568 0.51194 0.763478 0.5 1.73016V22.2679C0.511416 23.2352 1.30424 24.0102 2.27144 23.9999H22.7238C23.6921 24.011 24.4867 23.2362 24.5 22.2679V1.73016C24.4861 0.762411 23.6915 -0.0114782 22.7238 0.000128875"
								fill="white"
							/>
						</g>
						<defs>
							<clipPath id="clip0">
								<rect
									width="24"
									height="24"
									fill="white"
									transform="translate(0.5)"
								/>
							</clipPath>
						</defs>
					</svg>
					{buttonText}
				</Button>
			</Link>
		</>
	);
};

export async function getServerSideProps(context) {
	return {
		props: {
			csrfToken: await getCsrfToken(context),
		},
	};
}

PageLogin.layout = 'auth';
export default PageLogin;
