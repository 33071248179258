import cc from 'classcat';
import { TextColor, TextSize } from '../global/enum';

export enum TextPosition {
	center = 'center',
	left = 'left',
	right = 'right',
}

interface DividerProps {
	className?: string;
	title?: string;
	position?: TextPosition;
	textSize?: TextSize;
	textColor?: TextColor;
}

export const Divider: React.FC<DividerProps> = (props) => {
	const {
		className,
		title,
		textColor = TextColor.neutral,
		textSize = TextSize.sm,
		position = TextPosition.center,
	} = props;

	return (
		<div className={cc(['divider relative', className])}>
			<div
				className={cc(['absolute inset-0 flex w-full items-center'])}
				aria-hidden="true"
			>
				<div className={cc(['w-full border-t border-gray-300'])} />
			</div>
			<div
				className={cc([
					'divider-text relative flex ',
					{
						'justify-center': position === TextPosition.center,
						'justify-start': position === TextPosition.left,
						'justify-end': position === TextPosition.right,
					},
				])}
			>
				{title && (
					<span
						className={cc([
							'bg-white px-3 font-body uppercase',
							textColor,
							textSize,
						])}
					>
						{title}
					</span>
				)}
			</div>
		</div>
	);
};
